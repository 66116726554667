























import { Vue, Component, Prop, Watch } from 'vue-property-decorator'
import { PaginationMeta } from '@/store/types/index.js'

@Component({
  inheritAttrs: false,
})
export default class DataTable extends Vue {
  @Prop({ required :true })
  private headers!: Record<string, any>[]

  @Prop({ required :true })
  private items!: Record<string, any>[]

  @Prop({ required :true })
  private pagination!: PaginationMeta

  @Prop({ required: false })
  private page!: number

  @Prop({ default: false, type: Boolean })
  private scrollable!: boolean

  @Prop({ default: false, type: Boolean })
  private fixedFooter!: boolean

  private height: number | null = null

  private get tableHeight () {
    return this.scrollable ? this.height : null
  }

  private mounted () {
    if (this.scrollable)
      this.makeTableScrollable()
  }

  private makeTableScrollable () {
    const table = this.$refs.dataTable as Vue

    if (table) {
      const elDistanceFromTop = table.$el.getBoundingClientRect().top
      const restPageHeight= window.innerHeight - elDistanceFromTop
      const tableFooterHeight = table.$el.querySelector('.table-footer')?.getBoundingClientRect().height || 0
      const tableHeaderHeight = table.$el.querySelector('thead')?.getBoundingClientRect().height || 0
      const tableBodyHeight = table.$el.querySelector('tbody')?.getBoundingClientRect().height

      const pageContainerPaddingBottom = parseInt(
        window.getComputedStyle(table.$el.closest('.container') as Element)
          .getPropertyValue('padding-bottom'),
      )

      const tableBottomMargin = parseInt(
        window.getComputedStyle(table.$el.querySelector('.v-data-table__wrapper') as Element)
          .getPropertyValue('margin-bottom'),
      )

      if (tableBodyHeight) {
        const height = restPageHeight - tableFooterHeight - pageContainerPaddingBottom - tableBottomMargin
        this.height = tableBodyHeight + tableHeaderHeight < height ? null : height
      }
    }
  }

  @Watch('items')
  private watchItems () {
    this.$nextTick(this.makeTableScrollable)
  }
}
