var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"display-contents"},[_c('FiltersPanel',{staticClass:"sp-xxs full-width",attrs:{"filters":_vm.dictionary,"form-filters":_vm.filter,"drawerComponentName":"CarparkFilterForm","modalComponentName":"CarparkTableSettingModal"},model:{value:(_vm.activeStatus),callback:function ($$v) {_vm.activeStatus=$$v},expression:"activeStatus"}},[_c('VBtn',{staticClass:"sp-r-xxs",class:[{ 'btn--square': _vm.$vuetify.breakpoint.xsOnly },'btn--secondary'],on:{"click":function($event){return _vm.$openModal('CarparkDownloadModal', 'big')}}},[_vm._v(_vm._s(!_vm.$vuetify.breakpoint.xsOnly ? 'Excel' : '')),_c('VIcon',{attrs:{"color":"red","center":""}},[_vm._v("mdi-tray-arrow-down")])],1)],1),_c('div',{directives:[{name:"show",rawName:"v-show",value:(_vm.tableData && _vm.tableData.length),expression:"tableData && tableData.length"}],staticClass:"table full-width"},[_c('DataTable',{attrs:{"headers":_vm.headers,"items":_vm.tableData,"page":_vm.filter.page,"pagination":_vm.pagination,"scrollable":"","fixed-footer":""},on:{"update:page":function($event){return _vm.$set(_vm.filter, "page", $event)},"sortBy":_vm.changeSortBy,"sortDesc":_vm.changeSortDesc},scopedSlots:_vm._u([{key:"item.vin",fn:function(ref){
var item = ref.item;
return [_c('div',{staticClass:"content_copy"},[_c('div',{on:{"click":function($event){return _vm.goToCarInfo(item)}}},[_vm._v(_vm._s(item.vin))]),_c('VIcon',{attrs:{"color":"red","center":""},on:{"click":function($event){$event.stopPropagation();return _vm.copyText(item.vin)}}},[_vm._v("mdi-content-copy")])],1)]}},{key:"item.stateNumber",fn:function(ref){
var item = ref.item;
return [_c('div',{staticClass:"inline-additional"},[_vm._v(_vm._s(item.stateNumber.slice(0, 6)))]),_c('div',{staticClass:"inline-additional"},[_vm._v(_vm._s(item.stateNumber.slice(6)))])]}},{key:"item.casco",fn:function(ref){
var item = ref.item;
return [(item.casco && item.casco.length)?_c('a',{attrs:{"download":"","target":"_blank","href":item.casco},on:{"click":function($event){$event.stopPropagation();}}},[_c('VIcon',{attrs:{"color":"red","center":""}},[_vm._v("mdi-tray-arrow-down")])],1):_vm._e()]}},{key:"item.osago",fn:function(ref){
var item = ref.item;
return [(item.osago && item.osago.length)?_c('a',{attrs:{"download":"","target":"_blank","href":item.osago},on:{"click":function($event){$event.stopPropagation();}}},[_c('VIcon',{attrs:{"color":"red","center":""}},[_vm._v("mdi-tray-arrow-down")])],1):_vm._e()]}},{key:"item.replacementCar",fn:function(ref){
var item = ref.item;
return [(item.replacementCar)?_c('span',[_vm._v("Да")]):_c('span',[_vm._v("Нет")])]}},{key:"item.haulTireFitting",fn:function(ref){
var item = ref.item;
return [(item.haulTireFitting)?_c('span',[_vm._v("Да")]):_c('span',[_vm._v("Нет")])]}},{key:"no-data",fn:function(){return [_vm._v("Нет данных")]},proxy:true},{key:"footer",fn:function(ref){
var props = ref.props;
var on = ref.on;
return [_c('TableFooter',{attrs:{"pagination":props.pagination,"filter":_vm.filter,"mod":"table"},on:{"update:filter":function($event){_vm.filter=$event}}})]}}])})],1),((!_vm.tableData || !_vm.tableData.length) && _vm.isReady)?_c('CaptionCard',{staticClass:"empty-table",attrs:{"color":"transparent","padding-size":"sm","heading":"Нет данных"}},[_c('Paragraph',{attrs:{"size":"subtitle"}},[_vm._v("Попробуйте перезагрузить страницу или изменить параметры поиска.")])],1):_vm._e()],1)}
var staticRenderFns = []

export { render, staticRenderFns }