




































































import { Component, Mixins } from 'vue-property-decorator'

// COMPONENTS
import TableFooter from '@/components/TableFooter.vue'
import CaptionCard from '@/components/cards/CaptionCard.vue'
import FiltersPanel from '@/components/FiltersPanel.vue'

// INTERFACES
import { CarParkResource, CountResource } from '@/store/types'

// MIXINS
import SystemMixin from '@/mixins/SystemMixin'
import NotifyMixin from '@/mixins/NotifyMixin'

// STORE
import CarparkModule from '@/store/modules/fleet/carpark'

// LIBRARIES
import { Bind, Debounce } from 'lodash-decorators'
import { isEqual } from 'lodash'
import { DateTime } from 'luxon'
import DataTable from '@/components/tables/components/DataTable.vue'

@Component({
  components: {
    DataTable,
    TableFooter,
    CaptionCard,
    FiltersPanel,
  },
})
export default class CarparkTable extends Mixins(SystemMixin, NotifyMixin) {
  private get tableData (): CarParkResource[] {
    return CarparkModule.carparkData.data.map(item => ({
      ...item,
      plannedReturnDate: DateTime.fromFormat(`${item.plannedReturnDate}`, 'yyyy-MM-dd').toFormat('dd.MM.yy'),
      dateOfActOfTransfer: DateTime.fromFormat(`${item.dateOfActOfTransfer}`, 'yyyy-MM-dd').toFormat('dd.MM.yy'),
      dateOfActOfReturn: DateTime.fromFormat(`${item.dateOfActOfReturn}`, 'yyyy-MM-dd').toFormat('dd.MM.yy'),
      regDateCurrentMileage: DateTime.fromFormat(`${item.regDateCurrentMileage}`, 'yyyy-MM-dd').toFormat('dd.MM.yy'),
    }))
  }

  private get headers (): any {
    return CarparkModule.carparkTableHeaders.filter(item => item.visible)
  }

  private get dictionary (): CountResource[] {
    return CarparkModule.statusDictionary
  }

  private get filter () {
    return CarparkModule.filters
  }

  private set filter (value: any) {
    if (!isEqual(value, this.filter) || this.tableData.length === 0) {
      CarparkModule.setFilters(value)
      this.fetchTableData()
    }
  }

  private get pagination () {
    return CarparkModule.carparkData.meta
  }

  private get activeStatus (): string | undefined {
    return CarparkModule.filters.status
  }

  private set activeStatus (value: string | undefined) {
    CarparkModule.setActiveStatus(value)
    CarparkModule.getCarparkData(+this.entity)
  }

  private goToCarInfo (item: any){
    this.$router.push({ name: 'car-info', params: { carId: item.id } })
  }

  private isReady = false

  private created () {
    CarparkModule.resetFilters()

    CarparkModule.getCarparkHeaders()
    CarparkModule.getStatusDictionary(+this.entity)

    this.fetchTableData()
    this.$bus.$on('changeCarparkFilters', this.fetchTableData)
  }

  private changeSortBy (sortBy: string | string[]) {
    CarparkModule.setFilters({ ...this.filter, sortBy })
    this.fetchTableData()
  }

  private changeSortDesc (sortDesc: boolean | boolean[]) {
    CarparkModule.setFilters({ ...this.filter, sortDesc })
    this.fetchTableData()
  }

  private copyText (text: string) {
    navigator.clipboard.writeText(text.trim())
  }

  @Bind
  @Debounce(150)
  private fetchTableData () {
    CarparkModule.getStatusDictionary(+this.entity)
    CarparkModule.getCarparkData(+this.entity)
      .then(() => { this.isReady = true })
  }

  private beforeDestroy () {
    CarparkModule.resetFilters()
  }
}
