





import CarparkTable from '@/components/tables/CarparkTable.vue'
import { Component, Vue } from 'vue-property-decorator'

@Component({
  components: {
    CarparkTable,
  },
})
export default class CarparkTab extends Vue {}
